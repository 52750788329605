<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_cluster">Filter By Cluster</label>
            <v-select
              id="filter_cluster"
              v-model="selected.filter_cluster"
              :options="list.filter_clusters"
              label="cluster_name"
              :disabled="state.busy"
              placeholder="-- Please Select Cluster --"
            >
              <template #option="{ cluster_name }">
                <span>{{ cluster_name }}</span>
              </template>
              <template #no-options="">
                No Available Clusters
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="selectedArea">Filter by Area</label>
            <v-select
              id="selectedArea"
              v-model="selected.filter_area"
              :options="list.filter_areas"
              label="area_name"
              placeholder="-- Please Select Area --"
            >
              <template #option="{ area_name }">
                <strong>{{ area_name }}</strong>
              </template>
              <template #no-options="">
                No Available Areas
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-lead-form"
      scrollable
      no-close-on-backdrop
      title="Process"
      size="xl"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Personal Information"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="first name"
                    vid="first_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="first_name"><strong>First Name</strong></label>
                      <b-input
                        id="first_name"
                        v-model="facebookRaw.first_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter first name"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="last name"
                    vid="last_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="last_name"><strong>Last Name</strong></label>
                      <b-input
                        id="last_name"
                        v-model="facebookRaw.last_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter last name"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="facebook username"
                    vid="fb_username"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="fb_username"><strong>Facebook Username</strong></label>
                      <b-input
                        id="fb_username"
                        v-model="facebookRaw.fb_username"
                        type="fb_username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter facebook username"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mobile number"
                    vid="mobile_number"
                    rules="min:11|max:11"
                  >
                    <b-form-group>
                      <label for="mobile_number"><strong>Mobile Number</strong></label>
                      <b-input
                        id="mobile_number"
                        v-model="facebookRaw.mobile_number"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter mobile number"
                        autocomplete="off"
                        maxlength="11"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="lead type"
                    vid="lead_type"
                  >
                    <b-form-group>
                      <label for="lead_type"><strong>Lead Type</strong></label>
                      <b-form-select
                        id="lead_type"
                        v-model="facebookRaw.lead_type"
                        :options="options.lead_types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Lead Type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="gender"
                    vid="gender"
                  >
                    <b-form-group>
                      <label for="gender"><strong>Gender</strong></label>
                      <b-form-select
                        id="gender"
                        v-model="facebookRaw.gender"
                        :options="options.genders"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Gender --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="birth_date"
                    vid="birth_date"
                    rules=""
                  >
                    <b-form-group>
                      <label for="date">Birth Date</label>
                      <b-input
                        id="date"
                        v-model="facebookRaw.birth_date"
                        type="date"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter date"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="cluster"
                    vid="cluster"
                    rules=""
                  >
                    <b-form-group>
                      <label for="cluster"><strong>Cluster</strong></label>
                      <v-select
                        id="cluster"
                        v-model="selected.cluster"
                        :options="list.clusters"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.clusters"
                        :loading="state.fetching.clusters"
                        label="cluster_name"
                      >
                        <template #option="{ cluster_name }">
                          <strong>{{ cluster_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="area"
                    vid="area"
                    rules=""
                  >
                    <b-form-group>
                      <label for="areas"><strong>Area</strong></label>
                      <v-select
                        id="areas"
                        v-model="selected.area"
                        :options="list.areas"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.areas"
                        :loading="state.fetching.areas"
                        label="area_name"
                      >
                        <template #option="{ area_name }">
                          <strong>{{ area_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Areas
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="street name"
                    vid="street_name"
                    rules=""
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Street Name (Home Address)</strong></label>
                      <b-input
                        id="street_name"
                        v-model="facebookRaw.street_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter street name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="province"><strong>Province (Home Address)</strong></label>
                      <v-select
                        id="province"
                        v-model="selected.province"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.provinces"
                        :loading="state.fetching.provinces"
                        label="province_name"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="municipality"><strong>Municipality (Home Address)</strong></label>
                      <v-select
                        id="municipality"
                        v-model="selected.municipality"
                        :options="list.municipalities"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.municipalities"
                        :loading="state.fetching.municipalities"
                        label="municipality_name"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="barangay"><strong>Barangay (Home Address)</strong></label>
                      <v-select
                        id="barangay"
                        v-model="selected.barangay"
                        :options="list.barangays"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.barangays"
                        :loading="state.fetching.barangays"
                        label="barangay_name"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="farm address"
                    vid="farm_address"
                    rules=""
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Farm Address</strong></label>
                      <b-input
                        id="farm_address"
                        v-model="facebookRaw.farm_address"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter farm address"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Facebook Disposition"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition type"
                    vid="disposition_type"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="disposition_type"><strong>Disposition Type</strong></label>
                      <v-select
                        id="disposition_type"
                        v-model="selected.disposition_type"
                        :options="list.disposition_types"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="type_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Disposition Type --"
                      >
                        <template #option="{ type_name }">
                          <span>{{ type_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Disposition Types
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition"
                    vid="disposition"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="sub_disposition"><strong>Disposition</strong></label>
                      <v-select
                        id="disposition"
                        v-model="selected.disposition"
                        :options="list.dispositions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="disposition_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Disposition --"
                      >
                        <template #option="{ disposition_name }">
                          <span>{{ disposition_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Dispositions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="promotion"
                    vid="promotion"
                    :rules="rulesPromotion"
                  >
                    <b-form-group>
                      <label for="promotion"><strong>Promotion</strong></label>
                      <v-select
                        id="promotion"
                        v-model="selected.promotion"
                        :options="list.promotions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="promotion_name"
                        :disabled="(state.busy || facebookRaw.disposition_type === null || parseInt(facebookRaw.disposition_type)!==13)"
                        placeholder="-- Please Select Promotion --"
                      >
                        <template #option="{ promotion_name }">
                          <span>{{ promotion_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Promotions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:1000"
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Remarks</strong></label>
                      <b-textarea
                        id="remarks"
                        v-model="facebookRaw.remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Update Record
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AgentFacebookRawService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AgentFacebookRaw',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Facebook Raw'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          clusters: false,
          areas: false,
          provinces: false,
          municipalities: false,
          barangays: false
        }
      },
      filter: {
        filter_cluster: 'all',
        filter_area: 'all'
      },
      options: {
        lead_types: [
          { text: 'Farmer', value: 'Farmer' },
          { text: 'Non-Farmer', value: 'Non-Farmer' }
        ],
        genders: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' }
        ]
      },
      list: {
        clusters: [],
        areas: [],
        filter_clusters: [],
        filter_areas: [],
        provinces: [],
        municipalities: [],
        barangays: [],
        disposition_types: [],
        dispositions: [],
        promotions: []
      },
      selected: {
        log: null,
        current: null,
        cluster: null,
        area: null,
        filter_cluster: null,
        filter_area: null,
        province: null,
        municipality: null,
        barangay: null,
        disposition_type: null,
        disposition: null,
        promotion: null
      },
      facebookRaw: {
        id: 0,
        first_name: '',
        last_name: '',
        fb_username: '',
        mobile_number: '',
        lead_type: null,
        gender: null,
        cluster: null,
        area: null,
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        farm_address: '',
        disposition_type: null,
        disposition: null,
        promotion: null,
        remarks: '',
        source: '',
        source_remarks: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status' },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'fb_username' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    rulesPromotion () {
      if (parseInt(this.facebookRaw.disposition_type) === 8) {
        return 'required'
      }

      return ''
    }
  },

  watch: {
    'selected.cluster' (value) {
      this.facebookRaw.cluster = value?.id || null
      this.getAreas(this.facebookRaw.cluster)
    },

    'selected.area' (value) {
      this.facebookRaw.area = value?.id || null
    },

    'selected.province' (value) {
      this.facebookRaw.province = value?.id || null
      this.getMunicipalities(this.facebookRaw.province)
    },

    'selected.municipality' (value) {
      this.facebookRaw.municipality = value?.id || null
      this.getBarangays(this.facebookRaw.municipality)
    },

    'selected.barangay' (value) {
      this.facebookRaw.barangay = value?.id || null
    },

    'selected.disposition_type' (value) {
      this.facebookRaw.disposition_type = value?.id || null
      if (this.facebookRaw.disposition_type === null) {
        this.selected.promotion = null
      } else {
        if (parseInt(this.facebookRaw.disposition_type) !== 13) {
          this.selected.promotion = null
        }
      }
      this.getDispositions(this.facebookRaw.disposition_type)
    },

    'selected.disposition' (value) {
      this.facebookRaw.disposition = value?.id || null
    },

    'selected.promotion' (value) {
      this.facebookRaw.promotion = value?.id || null
    },

    'selected.filter_cluster' (value) {
      this.filter.filter_cluster = value?.id || 'all'
      this.getFilterAreas(value?.id || null)
      this.onFilterChanged()
    },

    'selected.filter_area' (value) {
      this.filter.filter_area = value?.id || 'all'
      this.onFilterChanged()
    }
  },

  mounted () {
    core.index()
    this.getProvinces()
    this.getClusters()
    this.getFilterClusters()
    this.getDispositionTypes()
    this.getPromotions()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentFacebookRawService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_cluster: this.filter.filter_cluster,
            filter_area: this.filter.filter_area,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getClusters () {
      this.state.fetching.clusters = true
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
        this.state.fetching.clusters = false
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      if (cluster) {
        this.state.fetching.areas = true
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data
          this.state.fetching.areas = false
          if (this.state.editing) {
            if (this.selected.current.area) {
              this.selected.area = {
                id: this.selected.current.area.id,
                area_name: this.selected.current.area.area_name
              }
              this.selected.current.area = null
            }
          }
        })
      } else {
        this.list.areas = []
      }
    },

    async getFilterClusters () {
      await SharedListService.getClusters().then(({ data }) => {
        this.list.filter_clusters = data
      })
    },

    async getFilterAreas (cluster) {
      this.selected.filter_area = null
      if (cluster) {
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.filter_areas = data
        })
      } else {
        this.list.filter_areas = []
      }
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getDispositionTypes () {
      await SharedListService.getDispositionTypes('channel_id=2').then(({ data }) => {
        this.list.disposition_types = data
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
        })
      } else {
        this.list.dispositions = []
      }
    },

    async getPromotions () {
      await SharedListService.getPromotions().then(({ data }) => {
        this.list.promotions = data
      })
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.selected.disposition_type = null
      this.selected.disposition = null
      this.selected.promotion = null
      this.facebookRaw.id = current.id
      this.facebookRaw.active = current.active
      this.facebookRaw.first_name = current.first_name
      this.facebookRaw.last_name = current.last_name
      this.facebookRaw.fb_username = current.fb_username
      this.facebookRaw.mobile_number = ''
      this.facebookRaw.lead_type = null
      this.facebookRaw.gender = null
      this.facebookRaw.birth_date = null
      this.facebookRaw.street_name = current.street_name
      this.facebookRaw.farm_address = current.farm_address
      this.facebookRaw.owner = current.user_id
      this.facebookRaw.source = current.source_id
      this.facebookRaw.source_remarks = current.remarks
      this.facebookRaw.remarks = ''

      if (current.cluster) {
        this.selected.cluster = {
          id: current.cluster.id,
          cluster_name: current.cluster.cluster_name
        }
      }

      if (current.area) {
        this.selected.area = {
          id: current.area.id,
          area_name: current.area.area_name
        }
      }

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      this.$bvModal.show('modal-lead-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to process facebook raw data?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentFacebookRawService.post(this.facebookRaw).then(({ data }) => {
          this.$bvModal.hide('modal-lead-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
